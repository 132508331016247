<template>
  <vs-popup
    classContent="popup-scb"
    title="ถอน KKP APP ยืนยัน"
    :active.sync="popup_kkp_app"
  >
    <vx-input-group class="mb-base">
      <template slot="prepend">
        <div class="prepend-text bg-primary">
          <span>INFO</span>
        </div>
      </template>

      <vs-input
        placeholder="Info"
        v-model="kkp_app_form.info"
        disabled
      />
    </vx-input-group>

    <vs-button
      :disabled="permissions"
      @click="confirm_kkp_app(
        withdrawdata.bank_code,
        withdrawdata.bank_number,
        withdrawdata.amount,
        withdrawdata.wd_id
      )"
      color="primary"
      type="filled"
    >
      ยืนยันการโอนเงิน
    </vs-button>
  </vs-popup>
</template>

<script>
export default {
  name: 'KKPBankPopup',
  props: [
    'popup_kkp_app',
    'kkp_app_form',
    'permissions',
    'withdrawdata',
    'verify_kkp_app',
    'confirm_kkp_app'
  ]
}
</script>

<style scoped>

</style>
