<template>
  <vs-popup
    classContent="popup-example"
    title="ถอนมือ"
    :active.sync="popup_confirm"
  >
    <div class="text-center">
      <h3 class="text-success">ยืนยันการถอน</h3>
      <p>ของ "{{ data_confirm.name }}"</p>
      <p>กดปุ่มยืนยันเพื่อใช้งานระบบ</p>
      <br />

      <!-- bank -->
      <div class="mt-4">
        <label class="vs-input--label">ธนาคาร</label>
        <vs-select class="w-full" v-model="selectBankTransferType" >
          <vs-select-item
            :key="index"
            :value="item.value"
            :text="item.text"
            v-for="(item, index) in options_bank"
          />
        </vs-select>
      </div>


          <!-- name bank -->
          <div class="mt-8">
            <label class="vs-input--label">เลือกชื่อบัญชี</label>
            <vs-select class="w-full mt-4" v-model="selectBankTransferID">
              <vs-select-item
                :key="index"
                :value="item.bank_id"
                :text="item.bank_fullname"
                v-for="(item, index) in option_bank_account"
              />
            </vs-select>
          </div>

          <label> อัพสลิปธนาคาร COPY & PASTE </label>
          <div class="mt-2 text-center">
            <!-- <vs-input class="inputx" placeholder="กรุณาวางรูป"/> -->
            <vs-input
              class="inputx"
              align="center"
              placeholder="กรุณาวางรูป"
              :value="selectedFile.name"
              @paste="onPaste"
            />
            <vs-checkbox
              disabled="true"
              class="mt-2"
              :color="chk_box.color"
              v-model="chk_box.data"
              >{{ chk_box.text }}</vs-checkbox
            >
            <canvas
              style="border: 1px solid grey"
              id="mycanvas"
              width="100"
              height="100"

            ></canvas>
          </div>

          <vs-button
            :disabled="
              $store.state.AppActiveUser.permissions.withdraw.action
                ? false
                : true
            "
            class="mx-1"
            size="small"
            color="success"
            type="filled"
            @click="confirm(data_confirm.wd_id)"
            >ยืนยัน
          </vs-button>
    </div>
  </vs-popup>
</template>

<script>
import axios from '../../axios'
import * as imageConversion from 'image-conversion'

export default {
  name: 'ConfirmPopup',
  data () {
    return {
      selectBankTransferID: '',
      selectBankTransferType: '',
      status_wd: {},
      option_bank_account: [],
      options_bank: [
        {
          text: 'Payment',
          value: 'PAYMENT'
        },
        {
          text: 'ไทยพาณิชย์',
          value: 'SCB'
        },
        {
          text: 'ออมสิน',
          value: 'GSB'
        },
        {
          text: 'กรุงศรี',
          value: 'BAY'
        },
        {
          text: 'กรุงไทย',
          value: 'KTB'
        },
        {
          text: 'กสิกรไทย',
          value: 'KBANK'
        },
        {
          text: 'เกียรตินาคินภัทร',
          value: 'KKP'
        }
      ],
      selectedFile: [],
      chk_box: { text: 'กรุณาวางรูป', color: 'danger', data: false }
    }
  },
  props: [
    'popup_confirm',
    'data_confirm',
    'set_store',
    'get_data',
    'clear_data',
    'banktransferScb',
    'banktransferGsb',
    'banktransferKtb',
    'banktransferBay',
    'banktransferKbank',
    'banktransferKKP',
    'closePopupConfirm'
  ],
  watch: {
    selectedFile () {
      try {
        if (this.selectedFile.name.length > 0) {
          this.chk_box.text = 'วางรูปสำเร็จ'
          this.chk_box.color = 'success'
          this.chk_box.data = true
        } else {
          this.chk_box.text = 'วางรูปไม่สำเร็จ'
          this.chk_box.color = 'danger'
          this.chk_box.data = false
        }
      } catch (err) {
        console.log(err.message)
      }
    },
    selectBankTransferType (newValue) {
      if (newValue === 'SCB') {
        this.option_bank_account = this.banktransferScb
      } else if (newValue === 'GSB') {
        this.option_bank_account = this.banktransferGsb
      } else if (newValue === 'KTB') {
        this.option_bank_account = this.banktransferKtb
      } else if (newValue === 'BAY') {
        this.option_bank_account = this.banktransferBay
      } else if (newValue === 'KBANK') {
        this.option_bank_account = this.banktransferKbank
      } else if (newValue === 'KKP') {
        this.option_bank_account = this.banktransferKKP
      } else if (newValue === 'PAYMENT') {
        this.option_bank_account = [
          { bank_id: 'VIZPAY', bank_fullname: 'VIZPAY' },
          { bank_id: 'HENGPAY', bank_fullname: 'HENGPAY' },
          { bank_id: 'LUCKY', bank_fullname: 'LUCKY' },
          { bank_id: 'AKPAY', bank_fullname: 'AKPAY' },
          { bank_id: 'OMEGAPAY', bank_fullname: 'OMEGAPAY' },
          { bank_id: 'POMPAY', bank_fullname: 'POMPAY' },
          { bank_id: 'FPAYMENT', bank_fullname: 'FPAYMENT' },
          { bank_id: '7P', bank_fullname: '7P' },
          { bank_id: 'MTP', bank_fullname: 'MTP' },
          { bank_id: 'CRYPTO', bank_fullname: 'CRYPTO' }
        ]
      }
    }
  },
  methods: {
    retrieveImageFromClipboardAsBlob (pasteEvent, callback) {
      if (pasteEvent.clipboardData === false) {
        if (typeof callback === 'function') {
          callback(undefined)
        }
      }

      const items = pasteEvent.clipboardData.items

      if (items === undefined) {
        if (typeof callback === 'function') {
          callback(undefined)
        }
      }

      for (let i = 0; i < items.length; i++) {
        // Skip content if not image
        if (items[i].type.indexOf('image') === -1) continue
        // Retrieve image on clipboard as blob
        const blob = items[i].getAsFile()

        if (typeof callback === 'function') {
          callback(blob)
          this.selectedFile = blob
        }
      }
    },
    async compressFile () {
      const res = await imageConversion.compress(this.selectedFile, {
        size: 100,
        quality: 0.5,
        type: 'image/jpg'
        // width: 300,
        // height: 200,
        // scale: 0.5
      })
      this.selectedFile = res
    },
    async onUploadFile (wd_id) {
      await this.compressFile()
      const formData = new FormData()
      formData.append('path', 'withdraw')
      formData.append('file', this.selectedFile)
      formData.append('topupid', wd_id) // appending file

      // sending file to the backend
      await axios.post('aws3/upload/upload', formData).then()
      // .catch(err => { console.log('errja', err) })
    },
    onPaste (evt) {
      // console.log('on paste', evt)
      // Handle the event
      this.retrieveImageFromClipboardAsBlob(evt, function (imageBlob) {
        // If there's an image, display it in the canvas
        if (imageBlob) {
          const canvas = document.getElementById('mycanvas')
          const ctx = canvas.getContext('2d')

          // Create an image to render the blob on the canvas
          const img = new Image()

          // Once the image loads, render the img on the canvas
          img.onload = function () {
            // Update dimensions of the canvas with the dimensions of the image


            // Draw the resized image
            const resizedWidth = 300 // Adjust the resized width as needed
            const resizedHeight = (img.height / img.width) * resizedWidth

            canvas.width = resizedWidth
            canvas.height = resizedHeight

            ctx.drawImage(img, 0, 0, resizedWidth, resizedHeight)
            // Draw the image


            // Display a smaller version of the image
            const thumbnailCanvas = document.getElementById('thumbnailCanvas')
            const thumbnailCtx = thumbnailCanvas.getContext('2d')

            const thumbnailSize = 100 // Adjust the size as needed
            thumbnailCanvas.width = thumbnailSize
            thumbnailCanvas.height = thumbnailSize
            thumbnailCtx.drawImage(img, 0, 0, img.width, img.height, 0, 0, thumbnailSize, thumbnailSize)
          }

          // Crossbrowser support for URL
          const URLObj = window.URL || window.webkitURL

          // Creates a DOMString containing a URL representing the object given in the parameter
          // namely the original Blob
          img.src = URLObj.createObjectURL(imageBlob)
        }
      })
    },
    async confirm (wd_id) {
      await axios
        .post('withdraw/confirmwithdraw', {
          bankAccount: `${this.selectBankTransferType}-${this.selectBankTransferID}`,
          type: this.selectBankTransferType,
          wd_id
        })
        .then((response) => (this.status_wd = response.data))
      if (this.status_wd.status === true) {
        this.$vs.notify({
          time: 3000,
          color: 'success',
          position: 'top-right',
          icon: 'check_box',
          title: 'ถอนสำเร็จ',
          text: this.status_wd.info
        })
        await this.onUploadFile(wd_id)
        this.set_store()
        this.get_data()
        this.closePopupConfirm()
      } else {
        this.$vs.notify({
          time: 8000,
          color: 'danger',
          position: 'top-right',
          icon: 'error',
          title: 'ถอนไม่สำเร็จ',
          text: this.status_wd.info
        })
      }
      this.clear_data()
      this.selectedFile = []
    }
  }
}
</script>

<style scoped>
  /* Styles specific to this component (if any) */
</style>
