<template>
  <vs-popup
    classContent="popup-example"
    title="เลือกธนาคาร"
    :active.sync="popupotp"
  >
    <!-- SCB -->
    <p
      v-if="scb_bank.length > 0"
      class="text-center mt-2 mx-2"
      style="color: white"
    >
      ลบ Cookie SCB
    </p>
    <vs-button
      :disabled="
        $store.state.AppActiveUser.permissions.withdraw.action ? false : true
      "
      class="mt-2 mx-2"
      size="small"
      color="rgb(86, 2, 126)"
      type="filled"
      @click="clear_scb_otp(item.bank_id, 'scb')"
      v-for="(item, index) in scb_bank"
      :key="index + 'CLEARSCB'"
      >SCB-{{ item.bank_id }}
    </vs-button>
    <br />
    <!-- GSB -->
    <p
      v-if="gsb_bank.length > 0"
      class="text-center mt-2 mx-2"
      style="color: white"
    >
      ลบ Cookie GSB
    </p>
    <vs-button
      :disabled="
        $store.state.AppActiveUser.permissions.withdraw.action ? false : true
      "
      class="mt-2 mx-2"
      size="small"
      color="rgb(236, 6, 141)"
      type="filled"
      @click="clear_gsb_otp(item.bank_id, 'gsb')"
      v-for="(item, index) in gsb_bank"
      :key="index + 'CLEARGSB'"
      >GSB-{{ item.bank_id }}
    </vs-button>
    <br />
    <!-- BAY -->
    <p
      v-if="bay_bank.length > 0"
      class="text-center mt-2 mx-2"
      style="color: white"
    >
      ลบ Cookie BAY
    </p>
    <vs-button
      :disabled="
        $store.state.AppActiveUser.permissions.withdraw.action ? false : true
      "
      class="mt-2 mx-2"
      size="small"
      color="rgb(255, 165, 0)"
      type="filled"
      @click="clear_bay_otp(item.bank_id, 'bay')"
      v-for="(item, index) in bay_bank"
      :key="index + 'CLEARBAY'"
      >BAY-{{ item.bank_id }}
    </vs-button>
    <br />
    <!-- KTB -->
    <p
      v-if="ktb_bank.length > 0"
      class="text-center mt-2 mx-2"
      style="color: white"
    >
      ลบ Cookie KTB
    </p>
    <vs-button
      :disabled="
        $store.state.AppActiveUser.permissions.withdraw.action ? false : true
      "
      class="mt-2 mx-2"
      size="small"
      color="#00a4e4"
      type="filled"
      @click="clear_ktb_otp(item.bank_id, 'ktb')"
      v-for="(item, index) in ktb_bank"
      :key="index + 'CLEARKTB'"
      >KTB-{{ item.bank_id }}
    </vs-button>
  </vs-popup>
</template>

<script>
import axios from '../../axios'

export default {
  name: 'ClearOtpPopup',
  props: [
    'popupotp',
    'scb_bank',
    'gsb_bank',
    'bay_bank',
    'ktb_bank',
    'permissions'
  ],
  methods: {
    statusclearcookie (id, bankcode) {
      this.$vs.notify({
        time: 5000,
        color: this.clearcookie.status === true ? 'success' : 'warning',
        position: 'top-right',
        icon: this.clearcookie.status === true ? 'check_box' : 'error',
        title:
          this.clearcookie.status === true ? 'ทำรายการสำเร็จ' : 'ทำรายการไม่สำเร็จ',
        text:
          this.clearcookie.status === true ? `clear cookie ${bankcode}-${id} สำเร็จ` : `clear cookie ${bankcode}-${id} ไม่สำเร็จ`
      })
    },
    async clear_scb_otp (id, bankcode) {
      await axios
        .post('withdraw/clearcookie', {
          bankid: id,
          bank: bankcode
        })
        .then((response) => (this.clearcookie = response.data))
      await this.statusclearcookie(id, bankcode)
    },
    async clear_gsb_otp (id, bankcode) {
      await axios
        .post('withdraw/clearcookie', {
          bankid: id,
          bank: bankcode
        })
        .then((response) => (this.clearcookie = response.data))
      await this.statusclearcookie(id, bankcode)
    },
    async clear_bay_otp (id, bankcode) {
      await axios
        .post('withdraw/clearcookie', {
          bankid: id,
          bank: bankcode
        })
        .then((response) => (this.clearcookie = response.data))
      await this.statusclearcookie(id, bankcode)
    },
    async clear_ktb_otp (id, bankcode) {
      await axios
        .post('withdraw/clearcookie', {
          bankid: id,
          bank: bankcode
        })
        .then((response) => (this.clearcookie = response.data))
      await this.statusclearcookie(id, bankcode)
    }
  }
}
</script>

<style scoped>
/* Styles specific to this component (if any) */
</style>
